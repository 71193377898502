import { IonButton, IonLabel } from "@ionic/react";
import { useEffect, useState } from "react";

// Some references:
// - https://yolandaqingniu.gitee.io/sdk-doc/en/flow/kitchen_scale.html
// - https://github.com/YolandaQingniu/sdk-ios-demo/blob/master/QNDeviceSDKDemo/KitchenVC.m

export const BluetoothComponent = () => {
  const [deviceId, setDeviceId] = useState();
  const [initialConnectionState, setInitialConnectionState] = useState({});
  const [weight, setWeight] = useState(0);

  const scanBl = () => {
    ble.scan(
      [],
      20,
      function (device) {
        if (device.name === "QN-KS") {
          setDeviceId(device.id);
        }
      },
      console.error,
    );
  };

  useEffect(() => {
    ble.connect(
      deviceId,
      (connection) => {
        console.log(connection);
        setInitialConnectionState(connection);
      },
      console.error,
    );
  }, [deviceId]);

  return (
    <div>
      <IonButton onClick={scanBl}>Connect to scales</IonButton>
      {initialConnectionState.advertising && (
        <IonButton
          onClick={() => {
            ble.startNotification(
              deviceId,
              "fff0",
              "fff1",
              function (data) {
                function parseWeight(buffer) {
                  const dataView = new DataView(buffer);
                  // Check the value at index 9
                  const valueAt10 = dataView.getUint16(10, true);
                  const valueAt9 = dataView.getUint16(9);

                  return valueAt10 > 30_000 ? valueAt9 / 10 : valueAt9;
                }

                // Usage:
                const weight = parseWeight(data);

                setWeight(weight);
              },
              function (failure) {
                console.log(failure);
                console.log("Failed to read characteristic from device.");
              },
            );
          }}
        >
          Read data
        </IonButton>
      )}
      <IonLabel>Weight: {weight}g</IonLabel>
    </div>
  );
};
