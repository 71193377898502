import React from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./home.css";
import { QRScanner } from "../components/barcode-scanner";
import { BluetoothComponent } from "../components/bluetooth";

const Home = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Takeback tool</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Takeback tool</IonTitle>
          </IonToolbar>
        </IonHeader>
        <QRScanner />

        <BluetoothComponent />
      </IonContent>
    </IonPage>
  );
};

export default Home;
